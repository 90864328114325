import React from "react";
import Search from "src/components/Shared/Search";
import Button from "src/components/Shared/Buttons/Button";
import { classNames } from "src/helpers/classNames";
import Preloader from "src/components/Shared/LoadingAnimations/Preloader";
import NoData from "src/components/Shared/NoData/NoData";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

// Header component
const Header = ({ searchEnabled = false, keyword = "", onKeywordChange = () => {}, addButtonEnabled = false, addButtonText = "", onAddButtonClick = () => {} }) => (
  <div className="mb-4 flex w-full gap-x-2">
    {searchEnabled && (
      <div className="w-full flex-1">
        <Search
          keyword={keyword}
          setKeyword={onKeywordChange}
          placeholder={"Search"}
          inline={true}
        />
      </div>
    )}
    {addButtonEnabled && (
      <Button
        version="secondary"
        className="!py-0"
        onClick={onAddButtonClick}>
        <div>{addButtonText}</div>
      </Button>
    )}
  </div>
);

// List component
const List = ({ listData = [], listDraggableEnabled = false, onDragEnd = () => {}, listComponent = () => {}, listLoaded = false }) =>
  listLoaded ? (
    listData.length > 0 ? (
      listDraggableEnabled ? (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div
                className="space-y-4 overflow-auto max-h-[calc(100vh-180px)] pr-1 -mr-1"
                {...provided.droppableProps}
                ref={provided.innerRef}>
                {listData.map((item, index) => (
                  <Draggable
                    key={item._id || item.id}
                    draggableId={item._id || item.id}
                    index={index}>
                    {(provided, snapshot) =>
                      listComponent({
                        item,
                        innerRef: provided.innerRef,
                        draggableProps: provided.draggableProps,
                        dragHandleProps: provided.dragHandleProps,
                      })
                    }
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      ) : (
        <div className="space-y-4">
          {listData.map((item, index) => (
            <div key={item._id || item.id}>{listComponent({ item })}</div>
          ))}
        </div>
      )
    ) : (
      <NoData />
    )
  ) : (
    <div className={classNames("h-20 transition-all duration-300", "opacity-100")}>
      <Preloader
        className="h-[80px]"
        circleDimension="10"
        size="base"
      />
    </div>
  );

// Selected content component
const SelectedContent = ({ listLoaded = false, selectedData = null, selectedComponent = () => {} }) =>
  listLoaded ? (
    selectedData ? (
      selectedComponent()
    ) : (
      <div className="relative flex max-w-full flex-col rounded-md border border-gray-200 shadow lg:min-h-[400px]">
        <div className="flex items-center justify-between gap-10 px-6 py-4"></div>
        <div className="relative h-full flex-grow border-t border-gray-200 px-3 py-3 sm:px-6 sm:py-4">
          <NoData />
        </div>
      </div>
    )
  ) : (
    <div className="relative flex max-w-full flex-col rounded-md border border-gray-200 shadow lg:min-h-[400px]">
      <div className="flex items-center justify-between gap-10 px-6 py-4"></div>
      <div className="relative h-full flex-grow border-t border-gray-200 px-3 py-3 sm:px-6 sm:py-4">
        <Preloader />
      </div>
    </div>
  );

// MultiColumnContent component
const MultiColumnContent = ({ headerContentConfig = {}, listContentConfig = {}, selectedContentConfig = {}, listToggle }) => {
  return (
    <div className="relative">
      <div className="flex w-full max-w-full flex-col gap-5 lg:flex-row">
        <div className="relative z-10 flex-shrink-0 lg:w-[360px] xl:w-[380px] 2xl:w-[430px]">
          <div className={classNames("relative lg:sticky lg:top-20 mt-3 lg:mt-0", listToggle ? "hidden lg:block" : "block")}>
            {headerContentConfig?.headerEnabled && (
              <div className="w-full flex-shrink-0">
                <Header {...headerContentConfig} />
              </div>
            )}
            <div className="flex-shrink flex-grow rounded-lg border-2 border-dashed border-gray-300 px-3 py-4">
              <List {...listContentConfig} />
            </div>
          </div>
        </div>
        <div className={classNames("w-full flex-shrink flex-grow lg:max-w-[calc(100%-356px)] lgx:max-w-[calc(100%-336px)] xl:max-w-[calc(100%-396px)] 2xl:max-w-[calc(100%-446px)]", listToggle ? "block" : "hidden lg:block")}>
          <SelectedContent
            {...selectedContentConfig}
            listLoaded={listContentConfig?.listLoaded || false}
          />
        </div>
      </div>
    </div>
  );
};

export default MultiColumnContent;
