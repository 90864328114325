import React from "react";
import { noLogo } from "src/config/host";

const AuthContainer = ({ children, site }) => {
  return (
    <div className="mt-12 sm:mx-auto sm:w-full sm:max-w-lg">
      <div className="bg-white shadow-md rounded-lg min-h-[183px] overflow-hidden">
        <div className="bg-headerColor p-2 pt-6">
          <div className="mx-auto h-20 w-auto px-3 lg:px-0 max-w-[400px]"> 
              <div className="w-full h-full py-0.5 inline-block">
                {site?.image_logo && (
                  <img
                    className="w-full h-full object-contain"
                    src={`${site?.image_logo}?${Date.now()}`}
                    alt="Logo"
                    onError={(e) => (e.target.src = `${noLogo}`)}
                  />
                )}
              </div>
          </div>
          
        </div>
        <div className="px-4 py-8 sm:px-10">{children}</div>
      </div>
    </div>
  );
};

export default AuthContainer;
