import { ArrowUpRightIcon, DocumentDuplicateIcon, CheckIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import { apiRequest } from "src/async/apiUtils";
import Button from "src/components/Shared/Buttons/Button";
import Input from "src/components/Shared/Forms/Inputs/Input";
import Modal from "src/components/Shared/Modal";
import { H3 } from "src/components/Shared/Text/Headers";
import { classNames } from "src/helpers/classNames";

const WorkspaceDomainManage = ({ isOpen = false, onCancel = () => {}, domain = {}, refreshDomain = () => {} }) => {
  const { id: workspaceId } = useParams();

  const [isTyping, setIsTyping] = useState(false);
  const [onSuccessLoaderStart, setOnSuccessLoaderStart] = useState(false);
  const [domainName, setDomainName] = useState("");

  const [isCopied, setIsCopied] = useState(false);

  // const isValidDomain = /^(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}$/.test(domainName.trim()) && /^(www\.)?[a-zA-Z0-9-]+\.[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/.test(domainName.trim());

  const isValidDomain = /^(www\.)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}$/.test(domainName.trim());

  const handleTypingLoader = () => {
    setIsTyping(true);
    setTimeout(() => setIsTyping(false), 700);
  };

  const onSubmit = async () => {
    setOnSuccessLoaderStart(true);

    try {
      // Validate
      if (!isValidDomain) {
        toast.error("Invalid url typed, please try again.");
        return;
      }

      const res = await apiRequest("POST", `workspaces/:workspace_id/domains`, { body: { domain: domainName }, params: { workspace_id: workspaceId } });

      refreshDomain();

      toast.success(res?.data?.message);

      // Clear state
      setDomainName("");
      onCancel();
    } catch (err) {
      toast.error(err.message);
    } finally {
      setOnSuccessLoaderStart(false);
    }
  };

  const copyCName = () => {
    navigator.clipboard.writeText(domain?.default_ingress);
    setIsCopied(true);

    setTimeout(() => setIsCopied(false), 4500);
  };

  useEffect(() => {
    setDomainName("");
  }, [isOpen]);

  return (
    <Modal
      title={"Add Domain"}
      isOpen={isOpen}
      size="xl"
      onCancel={onCancel}
      onSuccess={onSubmit}
      defaultOptions={{
        onSuccessButtonText: "Connect to CNAME",
        onSuccessButtonDisabled: !isValidDomain,
        onSuccessLoaderVisible: true,
        onSuccessLoaderStart,
      }}
      defaultStyles={{
        overFlowYVisible: false,
      }}>
      <div className="space-y-8">
        <div className="md:w-2/3">
          <div className="space-y-4">
            <div className="text-base text-gray-500">Clearsquare does not provide domain registration services. However, you can use a domain name that you already own. For any non-ASCII characters, please use Punycode representation.</div>
            <div className="relative space-y-1">
              <Input
                label={"Domain or Subdomain Name"}
                inline={true}
                value={domainName}
                loading={isTyping}
                onChange={(e) => setDomainName(e.target.value)}
                onKeyDown={handleTypingLoader}
              />
              <div className="text-sm text-gray-400">https://{domainName}</div>
            </div>
          </div>
        </div>
        {(!isValidDomain || true) && (
          <>
            <div className="relative space-y-3">
              {!isValidDomain && (
                <div className={classNames("absolute -left-2 -top-2 z-20 flex h-[calc(100%+16px)] w-[calc(100%+16px)] items-center justify-center rounded bg-gray-100 opacity-80")}>
                  <div className="flex w-full rotate-[-10deg] select-none justify-center text-center text-4xl font-extrabold text-gray-300 md:text-4xl lg:text-5xl xl:text-7xl">For DNS Management</div>
                </div>
              )}
              <H3 className="border-b border-gray-200 pb-2">Chose Domain Name System (DNS) Management</H3>
              <div className="md:w-2/3">
                <div className="text-base text-gray-500">This tech was invented in the Paleozoic Era, so we'll help walk you through these steps.</div>
              </div>
              <div className="relative rounded border border-highlightColor bg-white px-5 py-4">
                <div className="space-y-4">
                  <div className="max-w-[500px] text-base text-gray-500">Create a new CNAME record for your domain on your DNS Provider. Then, paste the CNAME Alias into the record so the domain points to this app.</div>
                  <div className="relative max-w-sm space-y-1">
                    <div className="relative flex h-12 w-full space-x-2">
                      <Input
                        disabled
                        label={"CNAME Alias"}
                        inline={true}
                        value={domain?.default_ingress}
                        wrapperClass={"w-full"}
                      />
                      <Button
                        version={"secondary"}
                        className="!h-12 !w-14"
                        onClick={copyCName}>
                        <DocumentDuplicateIcon className="h-6 w-6" />
                      </Button>
                      <div className="absolute right-[-290px] top-[10px] text-highlightColor">
                        {isCopied ? (
                          <div className="flex gap-x-1">
                            <CheckIcon className="h-6 w-6" />
                            The CNAME was copied to the clipboard
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="relative mt-14">
                      <div className="px-4 py-1">
                        <div className="flex font-semibold text-gray-400">
                          <div className="w-[200px]">TYPE</div>
                          <div className="w-[300px]">
                            VALUE <span className="pl-2 font-normal text-gray-300">(i.e. the client's domain)</span>
                          </div>
                          <div className="w-[300px]">HOST</div>
                        </div>
                      </div>
                      <div className="relative flex rounded-md border border-gray-300 px-5 py-4">
                        <div className="w-[200px] text-sm text-gray-700">CNAME</div>
                        <div className="flex w-[300px] space-x-1 text-sm text-gray-700 md:items-center">insights</div>
                        <div className="flex w-[300px] space-x-1 text-sm text-gray-700 md:items-center">{domain?.default_ingress}</div>
                      </div>
                    </div>
                    <div className="mb-6 ml-3 mt-2 text-sm font-medium text-gray-400">
                      Example for <span className="font-semibold text-gray-500">insights.yourclientsdomain.com</span>
                    </div>
                  </div>
                  <div className="flex">
                    <a
                      href="https://www.digitalocean.com/docs/app-platform/how-to/manage-domains/"
                      target="_blank"
                      rel="noreferrer"
                      className="inline-flex items-end space-x-1 text-sm font-semibold text-highlightColor underline">
                      <span>Learn More</span>
                      <ArrowUpRightIcon className="relative -top-0.5 h-3 w-3 stroke-[4]" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};

export default WorkspaceDomainManage;
