import React, { useState } from "react";
import axios from "axios";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import Input from "../Shared/Forms/Inputs/Input";
import Button from "../Shared/Buttons/Button";

// Fixing Leaflet's default icon issue
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

// Predefined IP services list
const predefinedIPs = {
  "54.193.7.64": {
    service: "Old WHM account",
    link: "https://adminnew.clearsquare.co:2087/cpsess7965030585/scripts4/listaccts",
  },
  "54.177.80.161": {
    service: "Domo Portal New",
    link: "https://domo-portals.clearsquare.co:2087/cpsess1935479459/scripts4/listaccts",
  },
  "13.56.128.59": {
    service: "DO MongoDB Backup (AWS)",
    link: "https://us-west-1.console.aws.amazon.com/ec2/home?region=us-west-1#InstanceDetails:instanceId=i-0b8814e939624e79d",
  },
  "54.241.200.159": {
    service: "amexcs (AWS)",
    link: "https://us-west-1.console.aws.amazon.com/ec2/home?region=us-west-1#InstanceDetails:instanceId=i-06c67b713eaef486a",
  },
  "160.20.10.169": {
    service: "NordVPN: A static IP address (for dev team)",
  },
};

const IpAddressChecker = () => {
  const [ipAddress, setIpAddress] = useState("");
  const [locationData, setLocationData] = useState(null);
  const [recognizedService, setRecognizedService] = useState(null);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const fetchLocation = async () => {
    setError("");
    setLocationData(null);
    setRecognizedService(null);

    if (!ipAddress) {
      setError("Please enter a valid IP address");
      return;
    }
    // Check if IP address is in predefined list
    if (predefinedIPs[ipAddress]) {
      setRecognizedService(predefinedIPs[ipAddress]);
    } else {
      try {
        setLoading(true);
        const response = await axios.get(`https://ipapi.co/${ipAddress}/json/`);
        setLoading(false);
        if (response?.data?.error) {
          setError(response?.data?.reason);
        } else {
          setLocationData(response?.data);
        }
      } catch (err) {
        setError("Error fetching data. Please try again.");
      }
    }
  };

  return (
    <div className="flex  w-full flex-col">
      <div className="flex gap-2">
        <Input
          type="text"
          autoComplete="off"
          name="ip-address"
          label="Ip Address"
          inline={true}
          value={ipAddress}
          onChange={(e) => setIpAddress(e.target.value)}
          wrapperClass="!w-96"
        />
        <Button
          loading={loading}
          onClick={fetchLocation}>
          Get Location
        </Button>
      </div>
      {error && <p className="mt-4 text-red-500">{error}</p>}
      {recognizedService && (
        <div className="mt-6 w-96 rounded-md border-l-4 border-yellow-500 bg-yellow-100 p-4 text-yellow-800">
          <h2 className="text-lg font-semibold">Recognized Service</h2>
          <p className="mt-2">
            <strong>Service:</strong> {recognizedService.service}
          </p>
          {recognizedService.link && (
            <p>
              <strong>Link:</strong>{" "}
              <a
                href={recognizedService.link}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-600 underline">
                {recognizedService.link}
              </a>
            </p>
          )}
        </div>
      )}
      {locationData && (
        <div className="mt-6  flex w-full gap-3">
          <div className=" flex w-3/12 flex-col rounded-lg border border-slate-200 bg-white p-6">
            <div className="flex flex-col justify-between space-y-2 text-lg">
              <h2 className="mb-4  pb-2 text-2xl font-semibold text-gray-900">Location Information</h2>
              <div className="flex flex-col space-y-1">
                <div className="text-gray-700">
                  <span className="font-semibold text-gray-900">IP Address:</span> {locationData?.ip}
                </div>
                <div className="text-gray-700">
                  <span className="font-semibold text-gray-900">Country:</span> {locationData?.country_name}
                </div>
                <div className="text-gray-700">
                  <span className="font-semibold text-gray-900">Region:</span> {locationData?.region}
                </div>
                <div className="text-gray-700">
                  <span className="font-semibold text-gray-900">City:</span> {locationData?.city}
                </div>
                <div className="text-gray-700">
                  <span className="font-semibold text-gray-900">Latitude:</span> {locationData?.latitude}
                </div>
                <div className="text-gray-700">
                  <span className="font-semibold text-gray-900">Longitude:</span> {locationData?.longitude}
                </div>
              </div>
            </div>
            <div className="mt-auto border-t border-gray-200 pt-4 text-sm text-gray-500">
              <div>
                <span className="font-semibold">Timezone:</span> {locationData?.timezone}
              </div>
              <div>
                <span className="font-semibold">Currency:</span> {locationData?.currency} ({locationData?.currency_name})
              </div>
              <div>
                <span className="font-semibold">Languages:</span> {locationData?.languages.split(",").slice(0, 3).join(", ")}
                {locationData?.languages.length > 3 && "..."}
              </div>
            </div>
          </div>

          <div className="w-9/12 overflow-hidden rounded-lg border ">
            <MapContainer
              center={[locationData?.latitude, locationData?.longitude]}
              zoom={13}
              style={{ height: "400px", width: "100%" }}>
              <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              />
              <Marker position={[locationData?.latitude, locationData?.longitude]}>
                <Popup>
                  {locationData.city}, {locationData?.country_capital}, {locationData?.country_name}
                </Popup>
              </Marker>
            </MapContainer>
          </div>
        </div>
      )}
    </div>
  );
};

export default IpAddressChecker;
