import { ArrowPathIcon } from "@heroicons/react/24/solid";
import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { manageSiteSettings } from "src/actions/site";
import { getWorkspaceSmtpSettings, manageWorkspaceSmtpSettings } from "src/actions/workspace";
import Button from "src/components/Shared/Buttons/Button";
import Section from "src/components/Shared/Containers/Section";
import Input from "src/components/Shared/Forms/FormikForms/Input";
import { H3 } from "src/components/Shared/Text/Headers";
import * as Yup from "yup";
import TestSmtpSettings from "./TestSmtpSettings";
import ToggleHeader from "src/components/Shared/Forms/Toggles/ToggleHeader";
import Toggle from "src/components/Shared/Forms/Toggles/Toggle";
import { classNames } from "src/helpers/classNames";

const OwnSmtpSettings = ({ siteData, manageSiteSettings, workspaceId = null }) => {
  const [smtpEmail, setSmtpEmail] = useState("");
  const [smtpHost, setSmtpHost] = useState("");
  const [smtpUser, setSmtpUser] = useState("");
  const [smtpPassword, setSmtpPassword] = useState("");
  const [smtpPort, setSmtpPort] = useState("");
  const [smtpSecure, setSmtpSecure] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [defaultCompanySmtp, setDefaultCompanySmtp] = useState(false);
  const [isHideCompanySmtp, setIsHideCompanySmtp] = useState(true);

  const onSubmit = async (values) => {
    try {
      setIsLoading(true);
      setIsDisabled(true);

      let body = {
        smtp_email: values.smtpEmail,
        smtp_host: values.smtpHost,
        smtp_user: values.smtpUser,
        smtp_port: values.smtpPort,
        smtp_secure: values.smtpSecure,
        default_company_smtp: values.defaultCompanySmtp,
      };

      if (values.smtpPassword) {
        body = { ...body, smtp_password: values.smtpPassword };
      }
      let message;
      if (workspaceId) {
        message = await manageWorkspaceSmtpSettings({ workspace_id: workspaceId, ...body });
      } else {
        message = await manageSiteSettings(body);
      }
      toast.success(message);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsDisabled(true);
      setIsLoading(false);
    }
  };

  const onClear = () => {
    setIsDisabled(true);
  };

  const getDefaultValue = (data) => {
    setSmtpEmail(data?.smtp_email || "");
    setSmtpHost(data?.smtp_host || "");
    setSmtpUser(data?.smtp_user || "");
    setSmtpPassword(data?.smtp_password || "");
    setSmtpPort(data?.smtp_port || "");
    setSmtpSecure(data?.smtp_secure);
    setDefaultCompanySmtp(data?.default_company_smtp || false);
    setIsHideCompanySmtp(data?.isHideCompanySmtp);
  };

  const getWorkspaceSmtp = async () => {
    const data = await getWorkspaceSmtpSettings({ workspace_id: workspaceId });
    getDefaultValue(data);
  };

  useEffect(() => {
    if (workspaceId) {
      getWorkspaceSmtp();
    } else {
      getDefaultValue(siteData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteData, workspaceId]);

  const getValidationSchema = ({ prevSmtpPassword, defaultCompanySmtp }) => {
    return Yup.object().shape({
      smtpEmail: defaultCompanySmtp ? Yup.string().notRequired() : Yup.string().required("Required"),
      smtpHost: defaultCompanySmtp ? Yup.string().notRequired() : Yup.string().required("Required"),
      smtpUser: defaultCompanySmtp ? Yup.string().notRequired() : Yup.string().required("Required"),
      smtpPort: defaultCompanySmtp ? Yup.string().notRequired() : Yup.number().required("Required"),
      smtpSecure: defaultCompanySmtp ? Yup.string().notRequired() : Yup.boolean().required("Required"),
      smtpPassword: defaultCompanySmtp || prevSmtpPassword ? Yup.string().notRequired() : Yup.string().required("Required"),
    });
  };

  const initialValues = {
    smtpEmail,
    smtpHost,
    smtpUser,
    smtpPassword: "",
    smtpPort,
    smtpSecure,
    defaultCompanySmtp,
    prevSmtpPassword: smtpPassword, // Assuming smtpPassword is the previous password
  };

  return (
    <Section>
      <div className="grid gap-y-8">
        <H3 caption="Adjust settings to allow for your clients to recieve emails from your domain.">SMTP Server Settings</H3>
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          validate={(values) => {
            try {
              // Dynamically generate the validation schema
              const schema = getValidationSchema({
                prevSmtpPassword: values.prevSmtpPassword,
                defaultCompanySmtp: values.defaultCompanySmtp,
              });

              // Validate the current values against the schema
              schema.validateSync(values, { abortEarly: false });
              return {}; // Return no errors if validation passes
            } catch (error) {
              // Return errors in Formik-compatible format
              const errors = {};
              if (error.inner) {
                error.inner.forEach((err) => {
                  if (!errors[err.path]) {
                    errors[err.path] = err.message;
                  }
                });
              }
              return errors;
            }
          }}
          onSubmit={async (values) => {
            onSubmit(values);
          }}>
          {({ values, errors, touched, setFieldValue, resetForm }) => {
            const handleOnChange = (e) => {
              setIsDisabled(false);
            };
            return (
              <Form onChange={handleOnChange}>
                <div className="grid space-y-4 sm:space-y-8">
                  {!isHideCompanySmtp && (
                    <ToggleHeader
                      position="left"
                      title='Use the temporary "support-analytics.com" SMTP'
                      subtitle="Until you have your own SMTP settings, you can use this development server to send emails.">
                      <Toggle
                        checked={values.defaultCompanySmtp}
                        onChange={() => {
                          setFieldValue("defaultCompanySmtp", !values.defaultCompanySmtp);
                          setIsDisabled(false);
                        }}
                      />
                    </ToggleHeader>
                  )}
                  <div className="relative flex w-full flex-wrap justify-between">
                    <div className={classNames("absolute -left-2 -top-2 z-40 flex h-[calc(100%+16px)] w-[calc(100%+16px)] items-center justify-center rounded-xl bg-gray-100 opacity-80", values.defaultCompanySmtp ? "absolute" : "hidden")}>
                      <div className="flex w-full rotate-[-10deg] select-none justify-center text-center text-4xl font-extrabold text-gray-300 md:text-4xl lg:text-5xl xl:text-7xl">Use for own SMTP</div>
                    </div>
                    <div className="flex w-full flex-wrap gap-y-6">
                      {/* Contact email */}
                      <div className="flex w-full flex-col gap-3 sm:flex-row">
                        <div className="w-full sm:w-1/2">
                          <Input
                            name="smtpEmail"
                            label="SMTP email"
                            inline={true}
                          />
                        </div>
                        {/* SMTP host */}
                        <div className="w-full sm:w-1/2">
                          <Input
                            name="smtpHost"
                            label="Host"
                            inline={true}
                          />
                        </div>
                      </div>
                      <div className="flex w-full flex-col gap-3 sm:flex-row">
                        {/* SMTP username */}
                        <div className="w-full sm:w-1/2">
                          <Input
                            name="smtpUser"
                            label="User"
                            inline={true}
                          />
                        </div>
                        {/* SMTP password */}
                        <div className="w-full sm:w-1/2">
                          <Input
                            placeholder={smtpPassword}
                            name="smtpPassword"
                            label="Password"
                            type="password"
                            autoComplete="new-password"
                            inline={true}
                          />
                        </div>
                      </div>
                      <div className="flex w-full flex-col gap-3 sm:flex-row">
                        {/* SMTP port */}
                        <div className="relative w-full sm:w-1/2">
                          {/* SMTP secure */}
                          <div className="absolute right-4 top-2 z-30 ml-2 flex cursor-pointer items-center gap-x-2 text-xs text-gray-700 opacity-70">
                            <input
                              className="cursor-pointer rounded text-gray-300 focus:ring-highlightColor"
                              type="checkbox"
                              checked={values.smtpSecure}
                              name="smtpSecure"
                              onChange={(e) => setFieldValue("smtpSecure", e.target.checked)}
                            />
                            <label className="cursor-pointer">SMTP Secure</label>
                          </div>
                          <div className="w-full">
                            <Input
                              name="smtpPort"
                              label="SMTP Port"
                              inline={true}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="flex w-full flex-col items-start justify-between gap-4 sm:flex-row">
                    <TestSmtpSettings workspaceId={workspaceId} />
                    <div className="flex flex-shrink-0 justify-between gap-x-4 pt-2">
                      <Button
                        version="gray"
                        disabled={isDisabled}
                        onClick={() => {
                          onClear();
                          resetForm();
                        }}>
                        Cancel
                      </Button>
                      <Button
                        disabled={isDisabled}
                        type="submit">
                        Save{isLoading ? <ArrowPathIcon className="ml-1 h-5 w-5 animate-spin" /> : <></>}
                      </Button>
                    </div>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </Section>
  );
};

const mapStateToProps = (state) => {
  return {
    siteData: state.site,
  };
};

export default connect(mapStateToProps, { manageSiteSettings })(OwnSmtpSettings);
