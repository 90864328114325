import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { workspaceIntegrationCredentialDelete } from "src/actions/workspace";
import { apiRequest } from "src/async/apiUtils";
import AddCredentialDropdown from "src/components/Shared/Buttons/AddCredentialDropdown";
import EditContainer from "src/components/Shared/Containers/EditContainer";
import MultiSelectDropdown from "src/components/Shared/Forms/Selects/MultiSelectDropdown";
import Preloader from "src/components/Shared/LoadingAnimations/Preloader";
import Modal from "src/components/Shared/Modal";
import NoDataDashedContainer from "src/components/Shared/NoData/NoDataDashedContainer";
import Search from "src/components/Shared/Search";
import WorkspaceIntegrationItem from "src/components/Workspaces/Workspace/Settings/WorkspaceIntegration/WorkspaceIntegrationItem";
import WorkspacesIntegrationCreatedByManageModal from "src/components/Workspaces/WorkspacesModals/WorkspacesIntegrationCreatedByManageModal";
import WorkspacesIntegrationModal from "src/components/Workspaces/WorkspacesModals/WorkspacesIntegrationModal";
import WorkspacesIntegrationPageListModal from "src/components/Workspaces/WorkspacesModals/WorkspacesIntegrationPageListModal";
import { classNames } from "src/helpers/classNames";
import useFetch from "src/hooks/useFetch";

const WorkspaceIntegrationsList = ({ is_global = false, user, workspaceId, workspaceDetails, ...props }) => {
  const [keyword, setkeyword] = useState("");
  const [selectedProviders, setSelectedProviders] = useState([]);
  const [selectedProviderCodes, setSelectedProviderCodes] = useState([]);
  const [selectedWorkspaces, setSelectedWorkspaces] = useState([]);
  const [selectedWorkspaceData, setSelectedWorkspaceData] = useState([]);
  const [providerDetails, setProviderDetails] = useState(null);
  const [embedType, setEmbedType] = useState(null);
  const [editItem, setEditItem] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isCreatedByModalOpen, setIsCreatedByModalOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [integrationId, setIntegrationId] = useState(null);
  const [credentialId, setCredentialId] = useState(null);
  const [deleteIsLoading, setDeleteIsLoading] = useState(false);
  const [, setIsAddIntegration] = useState(false);
  const [dataProviderList, setDataProviderList] = useState([]);
  const [makeDefaultButtonDisabled, setMakeDefaultButtonDisabled] = useState(false);
  const [editorUserList, setEditorUserList] = useState([]);
  const [integrationList, setIntegrationList] = useState([]);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [isPreloading, setIsPreloading] = useState(true);
  const [isPageListModalOpen, setIsPageListModalOpen] = useState(false);
  const [pageList, setPageList] = useState([]);

  const url = new URL(window.location);
  const queryParams = new URLSearchParams(url.search);
  const [defaultOpen, setDefaultOpen] = useState(false);

  const {
    response: { data: dataProviders },
  } = useFetch(workspaceId ? `/workspaces/:workspace_id/data-providers` : `/data-providers/global`, { params: { workspace_id: workspaceId } });

  const {
    response: { data: workspaces },
  } = useFetch(!workspaceId ? `/workspaces/list` : ``, { method: "post", data: { includes: ["integrations"] } });

  const {
    response: { data: integrations },
    status: { done: integrationsLoaded },
    refreshData: refreshIntegrations,
  } = useFetch(workspaceId ? `/workspaces/:workspace_id/integrations` : `/integrations/global`, { method: "post", data: { keyword, selectedProviders, selectedWorkspaces }, params: { workspace_id: workspaceId } });

  const {
    response: { data: editorUsers },
  } = useFetch(workspaceId ? `/workspaces/:workspace_id/editor-admin/list` : `/editor-admin/list`, { params: { workspace_id: workspaceId } });

  const deleteCredModalOpen = async (integration_id, credential_id) => {
    setIntegrationId(integration_id);
    setCredentialId(credential_id);
    setIsDeleteOpen(true);
  };

  const removeCredential = async () => {
    try {
      setDeleteIsLoading(true);
      const data = await props.workspaceIntegrationCredentialDelete({ integration_id: integrationId, workspace_id: workspaceId, credential_id: credentialId });

      if (data?.length === 0) setIsPreloading(true);
      setIntegrationList(data);

      if (workspaceDetails?.workspace_type === "JWT_FULL_EMBED") {
        apiRequest("post", `/workspaces/${workspaceId}/domo-data-count`, { body: {} });
      }

      setIsDeleteOpen(false);
      setDeleteIsLoading(false);
      refreshIntegrations();
    } catch (error) {
      toast.error(error.message);
    }
  };

  const openPageListModal = (pages = []) => {
    setIsPageListModalOpen(true);
    setPageList(pages);
  };

  useEffect(() => {
    let providerList = [];
    if (!workspaceId || workspaceDetails?.workspace_type === "IFRAME_EMBED") {
      setIsAddIntegration(true);
      providerList = dataProviders;
    } else {
      // For Edit embed workspace
      if (integrationList.length === 0) {
        providerList = dataProviders;
      } else {
        providerList = [];
      }
    }
    setIsAddIntegration(providerList.length > 0 ? true : false);
    setDataProviderList(
      providerList.map((dataProvider) => {
        if (dataProvider?.embed_types?.length > 0) {
          const checkFullEmbedGlobal = integrationList.find((integration) => integration?.code === dataProvider?.code)?.integrations?.find((integration) => !integration?.workspace_id && integration?.integration_type === "JWT_FULL_EMBED");
          return {
            ...dataProvider,
            embed_types: (workspaceId
              ? dataProvider?.embed_types?.filter((embed_type) => embed_type?.type === workspaceDetails?.workspace_type)
              : checkFullEmbedGlobal
                ? dataProvider?.embed_types.filter((embed_type) => embed_type?.type !== "JWT_FULL_EMBED")
                : dataProvider?.embed_types
            )
              .map((item) => ({ ...item, embed_fields: item?.embed_fields?.filter((field) => (workspaceId && (field?.used_for === "workspace" || field?.used_for === "both")) || (!workspaceId && (field?.used_for === "global" || field?.used_for === "both"))) }))
              .filter((item) => item?.embed_fields?.length > 0),
          };
        } else {
          return dataProvider;
        }
      }),
    );
  }, [workspaceId, integrationList, workspaceDetails, dataProviders]);

  useEffect(() => {
    refreshIntegrations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyword, selectedWorkspaces]);

  useEffect(() => {
    if (dataProviders?.length !== selectedProviderCodes?.length) {
      setSelectedWorkspaces(selectedWorkspaceData.filter((item) => item?.workspace_type === "IFRAME_EMBED" || selectedProviderCodes?.length === 0 || (selectedProviderCodes?.length > 0 && selectedProviderCodes?.includes(item?.code)))?.map((item) => item?._id));
    } else {
      refreshIntegrations();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProviderCodes, dataProviders]);

  useEffect(() => {
    if (integrationList?.length === 0 && !isDataLoaded) {
      if (integrations?.length > 0) {
        setIsDataLoaded(true);
      }
      setIntegrationList(integrations);
    }
  }, [integrationList, integrations, isDataLoaded]);

  useEffect(() => {
    if (integrationsLoaded) {
      setIsPreloading(false);
    }
  }, [integrationsLoaded]);

  useEffect(() => {
    if (queryParams.get("opencredentialmodal") && queryParams.get("credentialtype") && dataProviderList?.length > 0) {
      const dropdownData = dataProviderList?.find((item) => item?.code === queryParams.get("credentialtype")?.toLowerCase());
      if (dropdownData) {
        setProviderDetails(dropdownData);
        setEmbedType(dropdownData?.embed_types[0]);
        setEditItem(null);
        setIsOpen(true);
        setDefaultOpen(true);
        // Reverted the URL
        queryParams.delete("opencredentialmodal");
        queryParams.delete("credentialtype");
        window.history.pushState({}, "", `${url.origin}${url.pathname}?${queryParams.toString()}`);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataProviderList?.length]);

  return (
    <EditContainer
      title="Authentication Credentials"
      subtitle="Private embed using secure authentication"
      preview={{
        text: integrations?.length ? (
          <>
            <div>
              {integrations?.reduce((acc, integration) => {
                return acc + (integration?.integrations?.length || 0);
              }, 0)}
            </div>
            Embed Credential
            {integrations?.reduce((acc, integration) => {
              return acc + (integration?.integrations?.length || 0);
            }, 0) > 1 && "s"}
          </>
        ) : (
          "No saved credentials"
        ),
      }}
      defaultOptions={{
        onCancelButtonVisible: false,
        onSuccessButtonVisible: false,
      }}
      fullWidth={true}
      defaultOpen={defaultOpen || (integrationsLoaded && integrations?.length === 0)}>
      <>
        <div className="mb-9 pb-4">
          {/* {workspaceId ? <p className="pt-4">These listed API embed credentials are usable within this workspace</p> : null} */}
          {workspaceId ? null : (
            <div className="relative z-10 mb-3 flex flex-wrap gap-2 sm:flex-nowrap">
              <div className="w-full max-w-md">
                <Search
                  placeholder={"Search"}
                  keyword={keyword}
                  setKeyword={setkeyword}
                  inline={true}
                />
              </div>
              <div className="grid w-full grid-cols-2 gap-2 sm:w-auto">
                {dataProviders?.length > 1 && (
                  <div className="w-full flex-shrink-0 sm:w-48">
                    <MultiSelectDropdown
                      placeholder={"Vendor"}
                      inline={true}
                      checkboxPosition="left"
                      searchableFields={["name"]}
                      selectedItem={selectedProviders}
                      dropdownData={dataProviders.map((dataProvider) => ({
                        _id: dataProvider?._id,
                        name: dataProvider?.name,
                        image: dataProvider?.image,
                        object: dataProvider,
                      }))}
                      onClick={(event, item) => {
                        if (event.target.checked) {
                          setSelectedProviders((prevData) => [...prevData, item._id]);
                          setSelectedProviderCodes((prevData) => [...prevData, item?.object?.code]);
                        } else {
                          setSelectedProviders((prevData) => [...prevData.filter((data) => data !== item._id)]);
                          setSelectedProviderCodes((prevData) => [...prevData.filter((data) => data !== item?.object?.code)]);
                        }
                      }}
                    />
                  </div>
                )}
                {!workspaceId && workspaces?.length > 0 && (
                  <div className="w-full flex-shrink-0 sm:w-48">
                    <MultiSelectDropdown
                      searchableFields={["name"]}
                      inline={true}
                      checkboxPosition="left"
                      disabled={integrations?.length < 1}
                      placeholder="Workspace"
                      selectedItem={selectedWorkspaces}
                      dropdownData={workspaces
                        .filter((workspace) => workspace?.workspace_type === "IFRAME_EMBED" || selectedProviderCodes?.length === 0 || (selectedProviderCodes?.length > 0 && selectedProviderCodes?.includes(workspace?.integrations?.code)))
                        .map((workspace) => ({
                          _id: workspace?._id,
                          name: workspace?.name,
                          image: workspace.square_logo || workspace.image_logo || workspace.image_favicon,
                          object: workspace,
                        }))}
                      onClick={(event, item) => {
                        if (event.target.checked) {
                          setSelectedWorkspaces((prevData) => [...prevData, item._id]);
                          setSelectedWorkspaceData((prevData) => [...prevData, { _id: item._id, workspace_type: item?.object?.workspace_type, code: item?.object?.integrations?.code }]);
                        } else {
                          setSelectedWorkspaces((prevData) => [...prevData.filter((data) => data !== item._id)]);
                          setSelectedWorkspaceData((prevData) => [...prevData.filter((data) => data?._id !== item._id)]);
                        }
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
          )}
          {!isPreloading ? (
            integrations?.length > 0 ? (
              <div className="relative space-y-4">
                {integrations.map((integration, index) => {
                  return (
                    <WorkspaceIntegrationItem
                      key={integration?._id}
                      index={index}
                      length={integrations?.length}
                      integration={integration}
                      workspaceId={workspaceId}
                      is_global={is_global}
                      workspaceDetails={workspaceDetails}
                      editCredential={(provider, embedType, item) => {
                        setProviderDetails(provider);
                        setEmbedType(embedType);
                        setEditItem(item);
                        setIsOpen(true);
                      }}
                      openCreatedByModal={(provider, item) => {
                        if (workspaceId) {
                          setEditorUserList(editorUsers);
                        } else {
                          if (item?.workspace_id) {
                            setEditorUserList(editorUsers.filter((user) => user?.workspace_id?.includes(item?.workspace_id)));
                          } else {
                            setEditorUserList(editorUsers.filter((user) => user?.type === "admin"));
                          }
                        }
                        setProviderDetails(provider);
                        setEditItem(item);
                        setIsCreatedByModalOpen(true);
                      }}
                      removeCredential={deleteCredModalOpen}
                      makeDefaultButtonDisabled={makeDefaultButtonDisabled}
                      setMakeDefaultButtonDisabled={setMakeDefaultButtonDisabled}
                      refreshIntegrations={refreshIntegrations}
                      workspaces={workspaces}
                      openPageListModal={openPageListModal}
                    />
                  );
                })}

                <div className="relative">
                  <div className={classNames("absolute -top-4 right-0 transition-all duration-500 ease-out sm:-right-6", integrationsLoaded ? "opacity-0" : "opacity-100")}>
                    <Preloader
                      className="h-[40px]"
                      circleDimension="5"
                      size="sm"
                    />
                  </div>
                </div>
                {dataProviderList.length > 0 && (
                  <div className="flex">
                    <AddCredentialDropdown
                      // buttonClass={"!h-10 !px-0 !bg-transparent !border-none !text-highlightColor"}
                      dropdownData={dataProviderList}
                      onClick={(provider, embedType) => {
                        setProviderDetails(provider);
                        setEmbedType(embedType);
                        setEditItem(null);
                        setIsOpen(true);
                      }}
                    />
                  </div>
                )}
              </div>
            ) : (
              <NoDataDashedContainer
                title="No credentials found"
                addButton={
                  dataProviderList.length > 0 && (
                    <AddCredentialDropdown
                      align={"right"}
                      dropdownData={dataProviderList}
                      onClick={(provider, embedType) => {
                        setProviderDetails(provider);
                        setEmbedType(embedType);
                        setEditItem(null);
                        setIsOpen(true);
                      }}
                    />
                  )
                }
              />
            )
          ) : (
            <Preloader />
          )}
        </div>
        <WorkspacesIntegrationModal
          isOpen={isOpen}
          workspaceId={workspaceId}
          is_global={is_global}
          providerDetails={providerDetails}
          embedType={embedType}
          editItem={editItem}
          integrationList={integrationList}
          setIntegrationList={(data) => {
            if (integrationList?.length === 0) {
              setIsDataLoaded(true);
            }
            setIntegrationList(data);
          }}
          setIsPreloading={setIsPreloading}
          onCancel={() => {
            setIsOpen(false);
            setProviderDetails(null);
            setEmbedType(null);
          }}
          onSuccess={() => {
            refreshIntegrations();
            setIsOpen(false);
            setProviderDetails(null);
            setEmbedType(null);
          }}
        />
        <WorkspacesIntegrationCreatedByManageModal
          isOpen={isCreatedByModalOpen}
          workspaceId={workspaceId}
          is_global={is_global}
          providerDetails={providerDetails}
          editItem={editItem}
          users={editorUserList.map((user) => ({ _id: user?._id, name: user?.name }))}
          onCancel={() => {
            setIsCreatedByModalOpen(false);
            setProviderDetails(null);
            setEditItem(null);
          }}
          onSuccess={() => {
            refreshIntegrations();
            setIsCreatedByModalOpen(false);
          }}
        />
        <WorkspacesIntegrationPageListModal
          isOpen={isPageListModalOpen}
          pages={pageList}
          onCancel={() => setIsPageListModalOpen(false)}
        />
        <Modal
          title="Credential"
          secondaryTitle="Delete"
          isOpen={isDeleteOpen}
          onCancel={() => {
            setIntegrationId(null);
            setCredentialId(null);
            setIsDeleteOpen(false);
          }}
          isLoading={deleteIsLoading}
          onSuccess={removeCredential}
          defaultOptions={{
            onSuccessButtonText: "Delete",
          }}>
          <div className="flex flex-col gap-y-1 whitespace-nowrap px-3 py-6 text-gray-600">
            <p className="text-lg">
              Are you sure you want to <span className="px-1 font-semibold text-gray-700">DELETE</span>
              this credential?
            </p>
            <div className="text-gray-400">Once you delete this credential it's gone for good.</div>
          </div>
        </Modal>
      </>
    </EditContainer>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};
export default connect(mapStateToProps, { workspaceIntegrationCredentialDelete })(WorkspaceIntegrationsList);
